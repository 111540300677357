import deepcopy from "deepcopy";

// Removes the given keys from the object
// Example:
//   removeObjectKeys({ a: { b1: { c1: 1 }, b2: { c2: 2 } } }, ["a.b1"]) => { a: { b2: { c2: 2 } } }
export function removeObjectKeys<T extends Record<string, unknown>>(
	originalObject: T,
	pathKeysToRemove: string[],
): T {
	const copyObject = deepcopy(originalObject);

	for (const path of pathKeysToRemove) {
		const keys = path.split(".");
		if (keys.length === 0) continue;

		let obj: Record<string, unknown> = copyObject;

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i] as string;
			const isLastKey = i === keys.length - 1;

			if (obj === null || obj === undefined || typeof obj !== "object") break;

			if (isLastKey) {
				delete obj[key];
			} else {
				obj = obj[key] as Record<string, unknown>;
			}
		}
	}

	return copyObject;
}

// Saves the given keys from the object into a map, that can be restored later by calling restoreObjectKeys()
// Example:
//   saveObjectKeys({ a: { b1: { c1: 1 }, b2: { c2: 2 } } }, ["a.b1"]) => new Map([["a.b1", { c1: 1 }]])
export function saveObjectKeys<T extends Record<string, unknown>>(
	originalObject: T,
	pathKeysToSave: string[],
): Map<string, unknown> {
	const savedKeys: Map<string, unknown> = new Map();

	for (const path of pathKeysToSave) {
		const keys = path.split(".");
		if (keys.length === 0) continue;

		let obj: Record<string, unknown> = originalObject;

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i] as string;
			const isLastKey = i === keys.length - 1;

			if (obj === null || obj === undefined || typeof obj !== "object") {
				break;
			}

			if (isLastKey) {
				savedKeys.set(path, obj[key]);
			} else {
				obj = obj[key] as Record<string, unknown>;
			}
		}
	}

	return savedKeys;
}

// Restores the keys saved by saveObjectKeys() into the object
// Example:
//	restoreObjectKeys({ a: { b2: { c2: 2 } } }, new Map([["a.b1", { c1: 1 }]])) => { a: { b1: { c1: 1 }, b2: { c2: 2 } } }
export function restoreObjectKeys<T extends Record<string, unknown>>(
	originalObject: T,
	savedKeys: Map<string, unknown>,
): T {
	const copyObject = deepcopy(originalObject);

	for (const kv of savedKeys) {
		const path = kv[0];
		const value = kv[1];

		const keys = path.split(".");
		if (keys.length === 0) continue;

		let obj: Record<string, unknown> = copyObject;

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i] as string;
			const isLastKey = i === keys.length - 1;

			if (obj === null || obj === undefined || typeof obj !== "object") {
				break;
			}

			if (isLastKey) {
				obj[key] = value;
			} else {
				const nextObj = obj[key] as Record<string, unknown>;
				if (nextObj === null || nextObj === undefined) {
					// Missing path to object, add it
					const emptyObj = {};
					obj[key] = emptyObj;
					obj = emptyObj;
				} else {
					obj = nextObj;
				}
			}
		}
	}

	return copyObject;
}
