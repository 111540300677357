import {
	type AppID,
	type NubeSDKRuntime,
	type NubeSDKRuntimeEvent,
	type NubeSDKRuntimeState,
	type NubeSDKState,
	WORKER_PRIVATE_STATE_PATHS,
	type WorkerMessage,
} from "@tiendanube/nube-sdk-internal-types";
import deepcopy from "deepcopy";
import { removeObjectKeys } from "./object-keys-utils";
import { workerMessageHandler } from "./worker-handler";

export function addWorkerEventListener(
	id: AppID,
	worker: Worker,
	runtime: NubeSDKRuntime,
) {
	worker.addEventListener("message", (m: MessageEvent<string>) => {
		try {
			const message = JSON.parse(m.data) as WorkerMessage;

			if (String(message.app) === String(id)) {
				workerMessageHandler(message, runtime);
				return;
			}
			console.error(`App ${id} send message with invalid id ${message.app}`);
		} catch (err) {
			console.error("Unable to parse worker app message");
		}
	});

	runtime.on(
		"*",
		(state: NubeSDKRuntimeState, event: NubeSDKRuntimeEvent, appid, target) => {
			if (!target || target === appid) {
				const { apps, ...runtimeState } = state;

				const stateToSend: NubeSDKState = removeObjectKeys(
					runtimeState,
					WORKER_PRIVATE_STATE_PATHS,
				);

				worker.postMessage(
					JSON.stringify({
						type: event,
						state: stateToSend,
					}),
				);
			}
		},
	);
}
