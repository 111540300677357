import type { NubeSDKRuntimeState } from "@tiendanube/nube-sdk-internal-types";
import { createSDK } from "./create";
import { sortQueue } from "./sort-queue";

const sdk = createSDK({});

if (window.nubeSDK?.queue) {
	const sorted = window.nubeSDK.queue.sort(sortQueue);

	for (const [method, ...args] of sorted)
		Reflect.apply(sdk[method as "on" | "off" | "send"], sdk, args);
}

window.nubeSDK = sdk;
