import type {
	DeepPartial,
	NubeSDKRuntime,
	NubeSDKRuntimeListener,
	NubeSDKRuntimeState,
} from "@tiendanube/nube-sdk-internal-types";
import deepcopy from "deepcopy";
import { deepMerge } from "./deep-merge";

const FULL_REPLACE_PATHS = ["location.page.data", "ui"];

function buildAppUiSlotsRoute(appid: string): string {
	return `apps.${appid}.ui.slots`;
}

const EMPTY_STATE: NubeSDKRuntimeState = {
	cart: {
		id: "",
		validation: { status: "pending" },
		items: [],
		prices: {
			discount_coupon: 0,
			discount_gateway: 0,
			discount_promotion: 0,
			shipping: 0,
			subtotal: 0,
			total: 0,
		},
		coupon: null,
	},
	config: {
		has_cart_validation: false,
	},
	location: {
		url: "",
		page: { type: "category", data: { id: "", name: "" } },
	},
	store: {
		id: 0,
		name: "",
		domain: "",
		currency: "",
		language: "",
	},
	ui: {
		values: {},
		slots: {},
	},
	apps: {},
};

export function createSDK(
	initialData: DeepPartial<NubeSDKRuntimeState>,
): NubeSDKRuntime {
	const handlers = new Map<string, NubeSDKRuntimeListener[]>();
	let data: NubeSDKRuntimeState =
		deepMerge(EMPTY_STATE, initialData, FULL_REPLACE_PATHS) ?? EMPTY_STATE;

	return {
		on(event, listener) {
			const listeners = handlers.get(event) || [];
			handlers.set(event, [...listeners, listener]);
		},

		off(event, listener) {
			const listeners = handlers.get(event);

			if (listeners === undefined) return;

			handlers.set(
				event,
				listeners.filter((fn) => fn !== listener),
			);
		},

		send(appid, event, modifier, target) {
			if (modifier) {
				const result = deepMerge(data, modifier(data), [
					...FULL_REPLACE_PATHS,
					buildAppUiSlotsRoute(appid),
				]);
				if (result) data = result;
			}

			const eventListeners = handlers.get(event);

			if (eventListeners)
				for (const fn of eventListeners) fn(data, event, appid, target);

			const globalListeners = handlers.get("*");

			if (globalListeners)
				for (const fn of globalListeners) fn(data, event, appid, target);
		},

		getState() {
			return deepcopy(data);
		},
	};
}
