import type {
	AppState,
	NubeSDKRuntime,
	NubeSDKRuntimeState,
} from "@tiendanube/nube-sdk-internal-types";
import {
	getAppInitializer,
	serializeAppData,
	serializeInitialState,
} from "./code-handler";
import { addWorkerEventListener } from "./worker-event-listener";
import { createWorker } from "./worker-handler";

function register(
	runtime: NubeSDKRuntime,
	state: NubeSDKRuntimeState,
	initializer: string,
) {
	window.__NUBE_SDK_APPS__ = window.__NUBE_SDK_APPS__ || [];
	const { apps } = state;
	const registered: Record<string, AppState> = {};

	for (const appId in apps) {
		const app = apps[appId];
		if (app && !app.registered) {
			const worker = createWorker(
				serializeAppData(app),
				serializeInitialState(state),
				initializer,
			);

			addWorkerEventListener(appId, worker, runtime);
			app.registered = true;
			registered[appId] = app;
			window.__NUBE_SDK_APPS__.push({ id: appId, worker });
		}
	}

	if (Object.keys(registered).length > 0) {
		runtime.send("register", "app:registered", () => ({
			apps: registered,
		}));
	}
}

export async function attach(nube = window.nubeSDK) {
	const initializer = await getAppInitializer();
	const state = nube.getState();
	const hasApps = state?.apps && Object.keys(state.apps).length > 0;

	if (hasApps) register(nube, state, initializer);

	nube.on("app:register", (state) => register(nube, state, initializer));
}
