import type { AppConfig, Cart, NubeSDKState } from "@tiendanube/nube-sdk-types";

export type AppID = string;

export type AppState = {
	id: AppID;
	script: string;
	config?: AppConfig;
	registered?: boolean;
	cart?: Pick<Cart, "validation">;
	errors?: unknown[];
	ui?: NubeSDKState["ui"];
};

export type NubeSDKRuntimeState = NubeSDKState & {
	apps: Record<AppID, AppState>;
};

export const WORKER_PRIVATE_STATE_PATHS = ["ui.slots", "config"];
