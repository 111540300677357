import type {
	CartValidation,
	NubeComponent,
	NubeSDKRuntime,
	NubeSDKSendableEvent,
	UISlot,
	UISlots,
	WorkerMessage,
	WorkerMessageHandlerFunc,
} from "@tiendanube/nube-sdk-internal-types";

export function parseCartValidation(value: unknown): CartValidation {
	const val = value as CartValidation | undefined;

	if (!val) return { status: "pending" };

	switch (val.status) {
		case "fail":
			if (typeof val.reason === "string")
				return { status: "fail", reason: val.reason };
			break;

		case "success":
			return { status: "success" };
	}

	return { status: "pending" };
}

export const handleCartValidation: WorkerMessageHandlerFunc = ({
	app,
	changes,
}) => ({
	apps: {
		[app]: {
			cart: {
				validation: parseCartValidation(changes?.cart?.validation),
			},
		},
	},
});

export const handleSlotSet: WorkerMessageHandlerFunc = (
	{ app: appid, changes },
	state,
) => {
	const slots = changes?.ui?.slots;
	if (!slots) return {};

	const appUISlotsMerged: UISlots = {
		...(state.apps?.[appid]?.ui?.slots || {}),
	};
	const appUIValues = state.apps?.[appid]?.ui?.values || {};

	// Merge specific app ui slots into existing ones

	// We treat slots that are present in the slots array BUT don't have a component assigned as
	// an implicit removal of all components from the slot.
	for (const [slotKey, component] of Object.entries(slots)) {
		const slot = slotKey as UISlot;

		if (component) {
			appUISlotsMerged[slot] = component;
		} else {
			delete appUISlotsMerged[slot];
		}
	}

	// Merge all app ui slots together into the global ui slots

	// Start by collecting all used slots
	const usedSlots: Partial<Record<UISlot, NubeComponent[]>> = {};

	for (const [appKey, app] of Object.entries(state.apps)) {
		const appUISlots = appKey === appid ? appUISlotsMerged : app.ui?.slots;

		if (!appUISlots) continue;

		for (const [slotKey, component] of Object.entries(appUISlots)) {
			const slot = slotKey as UISlot;

			if (!usedSlots[slot]) {
				usedSlots[slot] = [];
			}

			usedSlots[slot].push(component);
		}
	}

	// Now merge the slots together, selecting the first component of each slot if there is more than one component,
	// in the future we can add a priority system to select the component to render, or generate a new container
	// that put ones after another
	const uiSlotsMerged: UISlots = {};
	const uiValues = state.ui.values;

	for (const [slotKey, components] of Object.entries(usedSlots)) {
		const slot = slotKey as UISlot;

		if (components.length > 1) {
			console.warn(
				`Slot ${slot} has more than one UI component assigned, only the first one will be used`,
			);
		}

		uiSlotsMerged[slot] = components[0];
	}

	return {
		apps: {
			[appid]: {
				ui: {
					slots: appUISlotsMerged,
					values: appUIValues,
				},
			},
		},
		ui: {
			slots: uiSlotsMerged,
			values: uiValues,
		},
	};
};

export const handleConfigSet: WorkerMessageHandlerFunc = ({
	app,
	changes,
}) => ({
	apps: {
		[app]: {
			config: {
				has_cart_validation: changes?.config?.has_cart_validation || false,
			},
		},
	},
});

export const handleWebWorkerError: WorkerMessageHandlerFunc = (
	{ app, error },
	state,
) => ({
	apps: {
		[app]: {
			errors: [...(state.apps?.[app]?.errors || []), error],
		},
	},
});

const handlers = new Map<
	NubeSDKSendableEvent | "WebWorkerError",
	WorkerMessageHandlerFunc
>([
	["config:set", handleConfigSet],
	["cart:validate", handleCartValidation],
	["ui:slot:set", handleSlotSet],
	["WebWorkerError", handleWebWorkerError],
]);

export function createWorkerMessageHandler(
	handlers: Map<
		NubeSDKSendableEvent | "WebWorkerError",
		WorkerMessageHandlerFunc
	>,
) {
	return (message: WorkerMessage, runtime: NubeSDKRuntime) => {
		const { app, type, changes } = message;
		const handler = handlers.get(type);

		if (handler) {
			runtime.send(app, type, (state) => handler(message, state));
			return;
		}

		if (changes) {
			runtime.send(app, type, () => changes);
			return;
		}

		runtime.send(app, type);
	};
}

export const workerMessageHandler = createWorkerMessageHandler(handlers);

export function createWorker(...parts: string[]): Worker {
	const blob = new Blob(parts, { type: "application/javascript" });
	const worker = new Worker(URL.createObjectURL(blob));
	return worker;
}
